import { useEffect } from "react"
import { useMapStore } from "../../../store"

export const OverlayChecker = () => {
  const overlayCountDown = useMapStore((state) => state.overlayCountDown)
  const updateOverlayCountDown = useMapStore((state) => state.setOverlayCountDown)
  const updateScreensaverCountDown = useMapStore((state) => state.setScreensaverCountDown)

  useEffect(() => {
    if (!overlayCountDown.isOpened) {
      updateScreensaverCountDown({ isOpened: false, timeLeft: 300 })

      let timer

      timer = setInterval(() => {
        updateOverlayCountDown({ isOpened: overlayCountDown.isOpened, timeLeft: overlayCountDown.timeLeft - 1 })
      }, 1000)

      if (overlayCountDown.timeLeft === 0) {
        updateOverlayCountDown({ isOpened: true, timeLeft: 60 })
      }

      return () => clearInterval(timer)
    }

    if (overlayCountDown.isOpened) {
      updateScreensaverCountDown({ isOpened: false, timeLeft: 300 })
    }

  }, [overlayCountDown.isOpened, overlayCountDown.timeLeft, updateOverlayCountDown, updateScreensaverCountDown])
  
  return (
    <div />
  )
}