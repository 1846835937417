import { useMapStore } from '../../../store'
import { SynagogueType } from '../../../types/synagogue-type'
import { useEffect, useState } from 'react'
import cn from 'classnames'
import styles from './SynagogueDescription.module.css'
import { motion, AnimatePresence } from 'framer-motion'
import { ChabadHousesList } from '../../../types/directus'

export const SynagogueDescription = (props: {
  isBackToAdress?: boolean
}) => {
  const synagogoguesList = useMapStore((state) => state.synagoguesList)
  const [selectedSynagogueData, setSelectedSynagogueData] = useState<ChabadHousesList>()
  const [isDescriptionOpened, setIsDescriptionOpened] = useState(false)
  const selectedId = useMapStore((state) => state.selectedId)
  const resetSelectedId = useMapStore((state) => state.resetSelectedId)
  const hideKeyboard = useMapStore((state) => state.setIsKeyboardHidden)
  const [selectedButtonIndex, setSelectedButtonIndex] = useState<number | null>(null)
  const [activeContactQr, setActiveContactQr] = useState<string>('')
  const [isScrolliconVisible, setIsScrollIconVisible] = useState(true)
  const updateGalleryModal = useMapStore((state) => state.updateGalleryModal)
  const isGalleryModalVisible = useMapStore((state) => state.galleryModal.isOpened)
  // const resetAdress = useMapStore((state) => state.resetAdress)

  useEffect(() => {
    if (selectedId && synagogoguesList) {
      synagogoguesList.forEach((synagogue) => {
        if (synagogue.id === selectedId) {
          setSelectedSynagogueData(synagogue)
        }
      })
    }
  }, [selectedId, synagogoguesList])

  useEffect(() => {
    setSelectedButtonIndex(0)
  }, [selectedId, synagogoguesList])

  return (
    <AnimatePresence>
      {selectedSynagogueData && <motion.div
        className={styles.wrapper}
        onClick={() => {
          // if (!props.isBackToAdress) {
          //   resetAdress()
          // }
          hideKeyboard()
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        exit={{ opacity: 0 }}
        onTouchMove={() => setIsScrollIconVisible(false)}
      >
        <div className={styles.back__btn__wrapper}>
          <button className={styles.back__btn} type='button' onClick={() => resetSelectedId()}>
            <img
              className={styles.back__icon}
              src="/icons/arrow.svg"
              alt="Стрелка назад"
            />
            НАЗАД
          </button>
        </div>

        <div className={styles.title__block}>
          <h2 className={styles.title}>{selectedSynagogueData.chabad_house_name}</h2>

          <div className={styles.description__block}>
            {!isDescriptionOpened && <p className={styles.description}>{selectedSynagogueData.short_description}</p>}
          
            {isDescriptionOpened && <>
              {(selectedSynagogueData.full_description as any[]).map((block) => {
                return <p
                  key={(selectedSynagogueData.full_description as any).indexOf(block)}
                  className={styles.description}
                  dangerouslySetInnerHTML={{__html: (selectedSynagogueData.full_description as any)[(selectedSynagogueData.full_description as any).indexOf(block)].block }}
                />
              })}
            </>}

            <button
              className={styles.read__full__btn}
              onClick={() => setIsDescriptionOpened(!isDescriptionOpened)}
              type='button'>
                {!isDescriptionOpened ? 'Читать полностью' : 'Скрыть'}
            </button>
          </div>
        </div>

        <div className={styles.block}>
          <span className={styles.block__title}>Глава центра</span>
          <span className={styles.block__info}>{selectedSynagogueData.head_name}</span>
        </div>

        <div className={styles.block}>
          <span className={styles.block__title}>Локация</span>
          <span className={styles.block__info}>{selectedSynagogueData.location}</span>
        </div>

        <div className={styles.block}>
          <span className={styles.block__title}>Телефон</span>
          {(selectedSynagogueData as any).phone_numbers.map((number: string) => {
            return <span
              key={(selectedSynagogueData as any).phone_numbers.indexOf(number)}
              className={styles.block__info}
              dangerouslySetInnerHTML={{__html: (selectedSynagogueData.phone_numbers as any)[(selectedSynagogueData.phone_numbers as any).indexOf(number)].phone_number }}
            />
          })}
        </div>

        <div className={styles.block}>
          <span className={styles.block__title}>Контакты</span>
          <div className={styles.contacts__btns__block}>
            {selectedSynagogueData.contacts && selectedSynagogueData.contacts.map((contact) => {
              return <button
                className={cn(styles.contact__btn,
                  {
                    [styles.contact__btn_active] : (selectedButtonIndex === null && selectedSynagogueData.contacts.indexOf(contact) === 0) || (selectedButtonIndex !== null && selectedSynagogueData.contacts.indexOf(contact) === selectedButtonIndex),
                    [styles.first__btn]: selectedSynagogueData.contacts.indexOf(contact) === 0
                  })
                }
                key={selectedSynagogueData.contacts.indexOf(contact)}
                type='button'
                onClick={() => {
                  setSelectedButtonIndex(selectedSynagogueData.contacts.indexOf(contact))
                  setActiveContactQr(`${process.env.REACT_APP_DIRECTUS_URL}/assets/${selectedSynagogueData.contacts[selectedSynagogueData.contacts.indexOf(contact)].qr_code}`)
                }}
              >
                <img alt="Иконка контакта" src={`${process.env.REACT_APP_DIRECTUS_URL}/assets/${contact.contact_icon}`} />
              </button>
            })}

            <div className={styles.qr__block}>
              <span>
                {!selectedButtonIndex ? 'Контакты' : selectedSynagogueData.contacts[selectedButtonIndex].title}
              </span>

              <img
                alt='Qr-код контакта синагоги'
                src={!selectedButtonIndex ? `${process.env.REACT_APP_DIRECTUS_URL}/assets/${selectedSynagogueData.contacts[0].qr_code}` : activeContactQr}
                style={{
                  width: '150px',
                  height: '150px'
                }}
              />
            </div>
          </div>
          
          <AnimatePresence>
            {selectedButtonIndex &&
              (selectedSynagogueData.contacts[selectedButtonIndex].title.toLowerCase() === 'instagram' ||
              selectedSynagogueData.contacts[selectedButtonIndex].title.toLowerCase() === 'facebook') ?
                <motion.span
                  className={styles.balderdash}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  *Проект Meta Platforms Inc., деятельность которой запрещена в России
                </motion.span>
              :
              null
            }
          </AnimatePresence>
        </div>

        {selectedSynagogueData && selectedSynagogueData.gallery && selectedSynagogueData.gallery.length > 0 &&
        <div className={styles.preview__wrapper}>
          <img src={`${process.env.REACT_APP_DIRECTUS_URL}/assets/${selectedSynagogueData.gallery_preview}`} alt="" onClick={() => updateGalleryModal({isOpened: true, id: selectedId})} />
          <button className={styles.gallery__link} type='button' onClick={() => updateGalleryModal({isOpened: true, id: selectedId})}>
            Смотреть фото
          </button>
        </div>}

        {isScrolliconVisible && !isGalleryModalVisible && <motion.img
          alt="Стрелка вниз"
          className={styles.arrow__down}
          src="/icons/hand_black.svg"
          initial={{ translateY: '50px', opacity: 0 }}
          animate={{ translateY: '-50px', opacity: [ 0, 1, 0 ] }}
          transition={{ duration: 2, ease: 'easeIn', repeat: Infinity }}
        />}
      </motion.div>}
    </AnimatePresence>
  )
}