import { useEffect } from "react"
import { useMapStore } from "../../../store"

export const ScreensaverChecker = () => {
  const overlayCountDown = useMapStore((state) => state.overlayCountDown)
  const screensaverCountDown = useMapStore((state) => state.screenSaverCountDown)
  const updateScreensaverCountDown = useMapStore((state) => state.setScreensaverCountDown)

  useEffect(() => {
    if (overlayCountDown.isOpened) {
      let timer

      timer = setInterval(() => {
        updateScreensaverCountDown({ isOpened: false, timeLeft: screensaverCountDown.timeLeft - 1 })
      }, 1000)

      if (screensaverCountDown.timeLeft === 0) {
        updateScreensaverCountDown({ isOpened: true, timeLeft: 300 })
      }

      return () => clearInterval(timer)
    }

  }, [overlayCountDown.isOpened, screensaverCountDown.timeLeft, updateScreensaverCountDown])
  
  return (
    <div />
  )
}