import { SynagogueListElement } from '../SynagogueListElement'
import { useMapStore } from '../../../store'
import { SynagogueType } from '../../../types/synagogue-type'
import styles from './SynagogueList.module.css'
import { GeoObjectType } from '../../../types/geo-object-type'
import { motion } from 'framer-motion'
import { ChabadHousesList } from '../../../types/directus'

export const SynagogueList = (props: {
  synagogoguesList?: ChabadHousesList[]
  geoObjectsList?: GeoObjectType[]
  onClick?: () => void
  isSynagoguesList?: boolean
  isGeoObjectList?: boolean
  isError?: boolean
  isGeocoderError?: boolean
  isFilteredByNearestId?: boolean
  nearestChabadId?: number
  title: string | null | undefined
}) => {
  const setSelectedId = useMapStore((state) => state.setSelectedId)
  const setSearchedCoordinates = useMapStore((state) => state.setSearchedCoordinates)
  const setGeocoderError = useMapStore((state) => state.setGeocoderError)
  const resetAdress = useMapStore((state) => state.resetAdress)

  return (
    <motion.div
      className={styles.wrapper}
      onClick={props.onClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <h2 className={styles.list__title}>
        {props.isSynagoguesList && !props.isFilteredByNearestId && props.title}
        {props.isGeoObjectList && 'Список адресов'}
        {props.isError && 'Адрес не найден'}
        {props.isGeocoderError && 'Ошибка'}
        {props.isFilteredByNearestId && 'Ближайший Бейт Хабад'}
      </h2>

      {props.isSynagoguesList && props.synagogoguesList && !props.nearestChabadId && <>
        <div>
          {props.synagogoguesList && props.synagogoguesList.map((synagogue) => {
            return <SynagogueListElement
            key={synagogue.id}
            text={synagogue.chabad_house_name || ''}
            onClick={() => setSelectedId(synagogue.id)}
          />})}
        </div>
      </>}

      
      {props.isSynagoguesList && props.synagogoguesList && props.nearestChabadId && <>
        <div>
          {props.synagogoguesList && props.synagogoguesList.map((synagogue) => {
            if (synagogue.id === props.nearestChabadId) {
              return <SynagogueListElement
              key={synagogue.id}
              text={synagogue.chabad_house_name || ''}
              onClick={() => setSelectedId(synagogue.id)}
            />
          }
          return null
          })}
        </div>
      </>}
      
      {props.isGeoObjectList && props.geoObjectsList && props.geoObjectsList.length > 0 && <>
        <div>
          {props.geoObjectsList.map((object) => {
            return <SynagogueListElement
            key={props.geoObjectsList && props.geoObjectsList.indexOf(object)}
            text={object.adress}
            onClick={() => setSearchedCoordinates(object.coordinates)}
          />})}
        </div>
      </>}

      {props.isError && <p className={styles.error}>
        Пожалуйста, введите адрес, находящийся в Москве или Московской области
      </p>}

      {props.isGeocoderError && <p className={styles.error}>
        Не удалось получить ответ от сервиса Геокодер. Пожалуйста, проверьте подключение к интернету,
        актуальность АПИ-ключа, количество доступных запросов.
      </p>}

      {props.isGeocoderError && <button
        className={styles.back__btn}
        type='button'
        onClick={() => {
          resetAdress()
          setGeocoderError(false)
        }}
      >
        Вернуться к списку Бейт Хабад
      </button>}
    </motion.div>
  )
}