import { Home } from './pages/Home'
import { Map } from './pages/Map'
import { NoInternet } from './pages/NoInternet'
import { useMapStore } from './store'
import './App.css'

export function App() {
  const currentPage = useMapStore((state) => state.currentPage)
  
  return (
    <main>
      {currentPage === 'home' && <Home />}
      {currentPage === 'map' && <Map />}
      {currentPage === 'no-internet' && <NoInternet />}
    </main>
  )
}
