import client from '../../api/sdk';
import { readItems, readSingleton } from '@directus/sdk';

export const getHomeScreen = async () => {
  return await client.request(
    readSingleton('home_screen', {
      fields: [
        '*'
      ]
    }),
  );
};

export const getChabadHouses = async () => {
  return await client.request(
    readItems('chabad_houses_list', {
      fields: [
        '*',
        { contacts: ['*'] },
        { gallery: ['directus_files_id'] }
      ]
    }),
  );
};

export const getChabadHousesClusters = async () => {
  return await client.request(
    readItems('chabad_houses_clusers_list', {
      fields: [
        '*',
        { included_chabad_houses: ['*'] }
      ]
    }),
  );
};

export const getMapPageInfo = async () => {
  return await client.request(
    readSingleton('map_page', {
      fields: [
        '*'
      ]
    }),
  );
};