import { motion } from 'framer-motion'
import { useEffect, useRef } from 'react'
import { HTMLBlock } from '../HTMLBlock'
import './AnimatedText.css'

export const AnimatedText = (props: {
	text: object | null | undefined
  onClick?: () => void
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const textBlockRef = useRef<HTMLDivElement>(null);
  const speed = .7;

  useEffect(() => {
    if (!containerRef.current || !textBlockRef.current) return;

    const text = textBlockRef.current
    const container = containerRef.current;
    const wrappers = container.querySelectorAll('.wrapper_animated');

    const animateText = (wrapper: HTMLDivElement) => {
      let translateY = container.offsetHeight - 120;

      const animate = () => {
        translateY -= speed;
        wrapper.style.transform = `translateY(${translateY}px)`;

        if (translateY <  -text.offsetHeight + 60) {
          wrapper.style.transform = `translateY(${container.offsetHeight}px)`;
          translateY = container.offsetHeight; // Reset translateY for new animation
        }

        requestAnimationFrame(animate);
      };

      animate();
    };

    wrappers.forEach((wrapper: any) => {
      animateText(wrapper);
    });
  }, []);

  return (
		<motion.div
      ref={containerRef}
      className='wrapper'
      onClick={props.onClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <div className="main__title__block">
        {Array.isArray(props.text) &&
        <HTMLBlock rawHtml={props.text[0].text} />}
      </div>

      <div className='gradient' />
      
			{props.text && <div
        ref={textBlockRef}
        className='wrapper_animated'
      >
        {Array.isArray(props.text) && props.text && props.text.map((item, index) => {
          if (Array.isArray(props.text) && props.text.indexOf(item) !== 0) {
            return <HTMLBlock key={index} className='paragraph' rawHtml={item.text} />
          }

          return null
        })}
			</div>}
		</motion.div>
  )
}