import { motion, AnimatePresence } from 'framer-motion'
import styles from './Overlay.module.css'

export const Overlay = (props: {
  onClick: () => void
  isVisible: boolean
}) => {
  return (
    <AnimatePresence>
      {props.isVisible && <motion.div
        className={styles.overlay}
        onClick={props.onClick}
        initial={{ opacity: 1 }}
        animate={{ opacity: .7 }}
        exit={{ opacity: 0 }}
      >
        <img className={styles.hand_first} src="/icons/hand.svg" alt="Рука" />
        <img className={styles.hand_second} src="/icons/hand.svg" alt="Рука" />
        <img className={styles.hand_third} src="/icons/hand.svg" alt="Рука" />
      </motion.div>}
    </AnimatePresence>

  )
}