import { useState } from 'react'
import { useMapStore } from '../../../store'
import { motion } from 'framer-motion'
import { useSwipeable } from 'react-swipeable'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/opacity.css'
import styles from './GalleryModal.module.css'
import { ChabadHousesListFiles } from '../../../types/directus'

export const Gallerymodal = (props: {
  selectedSynagogueId: number
  onClose: () => void
}) => {
  const synagoguesList = useMapStore((state) => state.synagoguesList)
  const selectedSynagogue = synagoguesList.filter((item) => item.id === props.selectedSynagogueId)
  // const selectedSynagogue[0].gallery = selectedSynagogue[0].gallery.filter((item) => !item.includes('preview'))
  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      if (currentImageIndex - 1 < 0) {
        setCurrentImageIndex(selectedSynagogue[0].gallery.length - 1)
      } else {
        setCurrentImageIndex(currentImageIndex - 1)
      }
    },

    onSwipedRight: () => {
      if (currentImageIndex + 1 >= selectedSynagogue[0].gallery.length) {
        setCurrentImageIndex(0)
      } else {
        setCurrentImageIndex(currentImageIndex + 1)
      }
    }
  })

  const handleBack = () => {
    if (currentImageIndex - 1 < 0) {
      setCurrentImageIndex(selectedSynagogue[0].gallery.length - 1)
    } else {
      setCurrentImageIndex(currentImageIndex - 1)
    }
  }

  const handleForward = () => {
    if (currentImageIndex + 1 >= selectedSynagogue[0].gallery.length) {
      setCurrentImageIndex(0)
    } else {
      setCurrentImageIndex(currentImageIndex + 1)
    }
  }

  return (<>
      <div className={styles.overlay} />
      <div className={styles.gallery} { ...swipeHandlers }>
        {selectedSynagogue[0].gallery.length > 1 &&
          <motion.button
            className={styles.nav__btn}
            type='button'
            onClick={handleBack}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <img
              alt="Назад"
              src="/icons/light-arrow.svg"
            />
          </motion.button>}

        {selectedSynagogue && selectedSynagogue[0].gallery.length > 0 && <>
          {selectedSynagogue[0].gallery.map((item: ChabadHousesListFiles) => {
            if (selectedSynagogue[0].gallery.indexOf(item) === currentImageIndex) {
                return <LazyLoadImage
                  alt="Фото синагоги"
                  className={styles.photo}
                  src={`${process.env.REACT_APP_DIRECTUS_URL}/assets/${item.directus_files_id}`}
                  effect='opacity'
                />
            } else {
              return null
            }
          })}
        </>}

        {selectedSynagogue[0].gallery.length > 1 &&
        <motion.button
          className={styles.nav__btn_rotated}
          type='button'
          onClick={handleForward}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <img
            alt="Вперёд"
            src="/icons/light-arrow.svg"
          />
        </motion.button>}
      </div>

      <motion.button
        className={styles.close__link}
        type='button'
        onClick={props.onClose}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <img
          alt="Закрыть"
          src="/icons/cross.svg"
        />
        закрыть
      </motion.button>
    </>
  )
}