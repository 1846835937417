import { useEffect, useState } from "react"
import { useMapStore } from "../../../store"
import styles from './InternetConnectionChecker.module.css'

export const InternetConnectionChecker = () => {  
  const [isOnline, setIsOnline] = useState(navigator.onLine)
  const currentpage = useMapStore((state) => state.currentPage)
  const setCurrentpage = useMapStore((state) => state.setCurrentPage)

  useEffect(() => {
    window.addEventListener('online', () => setIsOnline(true))
    window.addEventListener('offline', () => setIsOnline(false))
    return () => {
      window.removeEventListener('online', () => setIsOnline(true))
      window.removeEventListener('offline', () => setIsOnline(false))
    }
  }, [])

  useEffect(() => {
    if (!isOnline && currentpage !== 'no-internet') {
      setCurrentpage('no-internet')
    }

    if (isOnline && currentpage === 'no-internet') {
      setCurrentpage('map')
    }
  }, [currentpage, isOnline, setCurrentpage])
  
  return (
    <div className={styles.block} />
  )
}