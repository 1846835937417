import { useCallback, useEffect, useState } from 'react'
import 'react-simple-keyboard/build/css/index.css'
import { Overlay } from '../../components/layout/Overlay'
import { AnimatedText } from '../../components/ui/AnimatedText'
import { ScreensaverOverlay } from '../../components/layout/ScreensaverOverlay'
import { StaticText } from '../../components/ui/StaticText'
import { InternetConnectionChecker } from '../../components/layout/InternetConnectionChecker'
import Lottie from 'react-lottie'
import lottieRu from '../../lotties/ru.json'
import lottieEng from '../../lotties/eng.json'
import { useMapStore } from '../../store'
import { motion } from 'framer-motion'
import { OverlayChecker } from '../../components/layout/OverlayChecker'
import { ScreensaverChecker } from '../../components/layout/ScreensaverChecker'
import { getHomeScreen } from '../../utils/directus/requests'
import { HomeScreen } from '../../types/directus'
import styles from './Home.module.css'

export const Home = () => {
  const isScreenSaverVisible = useMapStore((state) => state.screenSaverCountDown.isOpened)
  const [homeInfo, setHomeInfo] = useState<HomeScreen>()
  const [selectedLanguage, setSelectedLanguage] = useState('')
  const [isAnimatedTextClicked, setIsAnimatedTextClicked] = useState(false)
  const [animatedTextCountDown, setAnimatedTextCountdown] = useState(7)
  
  const isOverlayVisible = useMapStore((state) => state.overlayCountDown.isOpened)
  const updateOverlayCountDown = useMapStore((state) => state.setOverlayCountDown)

  const setCurrentPage = useMapStore((state) => state.setCurrentPage)

  const getHomeScreenInfo = useCallback(async () => {
    try {
      const homeScreenInfo = await getHomeScreen();
      
      if (homeScreenInfo) {
        setHomeInfo(homeScreenInfo);
      }
    } catch (error) {
      console.error('Error fetching texts:', error);
    }
  }, [])

  useEffect(() => {
    getHomeScreenInfo()
  }, [getHomeScreenInfo])

  const defaultOptionsRu = {
    loop: false,
    autoplay: false,
    animationData: lottieEng,
    className: 'switch-language-btn'
  }

  const defaultOptionsEng = {
    loop: false,
    autoplay: false,
    animationData: lottieRu,
    className: 'switch-language-btn'
  }

  const animatedTextTick = useCallback(() => {
    setAnimatedTextCountdown(prevSeconds => prevSeconds - 1)
  }, [])

  const resetAnimatedTextCountDown = () => {
    setAnimatedTextCountdown(10)
  }
  
  useEffect(() => {
    if (animatedTextCountDown <= 0) {
      setIsAnimatedTextClicked(false)
      // setOverlayCountdown(30)
      resetAnimatedTextCountDown()
    }

    else if (animatedTextCountDown > 0) {
      const intervalId = setInterval(animatedTextTick, 2000)
      
      return () => clearInterval(intervalId)
    }
  }, [animatedTextTick, animatedTextCountDown])

  const handleLanguageChange = () => {
    if (selectedLanguage === '' || selectedLanguage === 'ru') {
      setTimeout(() => {
        updateOverlayCountDown({ isOpened: false, timeLeft: 60 })
        setSelectedLanguage('eng')
      }, 500)
    }

    if (selectedLanguage === 'eng') {
      setTimeout(() => {
        updateOverlayCountDown({ isOpened: false, timeLeft: 60 })
        setSelectedLanguage('ru')
      }, 500)
    }
  }

  return (
    <motion.main
      className={styles.app}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <div className={styles.gradient__overlay_bottom} />
      <OverlayChecker />
      <ScreensaverChecker />
      <div className={styles.link__div} onClick={() => setCurrentPage('map')} />
      <InternetConnectionChecker />

      <Overlay
        isVisible={isOverlayVisible}
        onClick={() => {
          updateOverlayCountDown({ isOpened: false, timeLeft: 60 })
        }}
      />

      <ScreensaverOverlay isVisible={isScreenSaverVisible} />

      {!isAnimatedTextClicked && homeInfo && typeof homeInfo.russian_text === 'object' && typeof homeInfo.english_text === 'object' &&
        <AnimatedText
        text={(selectedLanguage === 'ru' || selectedLanguage === '') ? homeInfo.russian_text : homeInfo.english_text}
        onClick={() => {
          setIsAnimatedTextClicked(true)
          updateOverlayCountDown({ isOpened: false, timeLeft: 60 })
        }}
      />}

      {isAnimatedTextClicked && homeInfo && typeof homeInfo.russian_text === 'object' && typeof homeInfo.english_text === 'object' && <StaticText
        text={(selectedLanguage === 'ru' || selectedLanguage === '') ? homeInfo.russian_text : homeInfo.english_text}
        onClick={() => {
          setAnimatedTextCountdown(7)
          setIsAnimatedTextClicked(true)
          updateOverlayCountDown({ isOpened: false, timeLeft: 60 })
        }}
        onTouchMove={() => {
          setAnimatedTextCountdown(7)
          updateOverlayCountDown({ isOpened: false, timeLeft: 60 })
        }}
      />}

      {homeInfo && <motion.div
        className={styles.buttons__block}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <button
          className={styles.button}
          onClick={() => setCurrentPage('map')}
          type='button'
        >
          <img
            alt="Иконка карты"
            src={homeInfo ? `${process.env.REACT_APP_DIRECTUS_URL}/assets/${homeInfo.button_icon}` : '/icons/map-icon.svg'}
          />
          
          {homeInfo && (selectedLanguage === 'ru' || selectedLanguage === '') &&  homeInfo.russian_button_text}
          {homeInfo && selectedLanguage === 'eng' &&  homeInfo.english_button_text}
        </button>
        
        <button
          className={styles.switch__language__btn}
          onClick={handleLanguageChange}
        >
          {(selectedLanguage === 'ru' || selectedLanguage === '') && <Lottie 
            options={defaultOptionsRu}
            isStopped={isOverlayVisible || selectedLanguage === '' || selectedLanguage === 'ru'}
          />}

          {selectedLanguage === 'eng' && <Lottie 
            options={defaultOptionsEng}
            isStopped={isOverlayVisible || selectedLanguage === 'eng'}
          />}
        </button>
      </motion.div>}
    </motion.main>
  )
}
