import styles from './SynagogueListElement.module.css'

export const SynagogueListElement = (props: {
  onClick?: () => void
  text: string
}) => {
  return (
    <div className={styles.wrapper} onClick={props.onClick}>
      {props.text}
    </div>
  )
}