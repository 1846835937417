import DOMPurify from 'isomorphic-dompurify'

export const HTMLBlock = function HTMLBlock(props: {
  className?: string
  innerRef?: any
  rawHtml: string
}) {
  const sanitizedHtml = DOMPurify.sanitize(props.rawHtml,{ ADD_ATTR: ['target'], FORBID_ATTR: ['rel'] });

  return (
    <div ref={props.innerRef} className={props.className} dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
  )
}
