import { useMapStore } from '../../../store'
import Keyboard from 'react-simple-keyboard'
import { formatAddress } from '../../../helpers/format-adress'
import { GeoObjectType } from '../../../types/geo-object-type'
import { motion } from 'framer-motion'
import styles from './TouchKeyboard.module.css'

export const TouchKeyboard = (props: {
  backButtonText: string | null | undefined
}) => {
  const adress = useMapStore((state) => state.adress)
  const setAdress = useMapStore((state) => state.setAdress)
  const hideKeyboard = useMapStore((state) => state.setIsKeyboardHidden)
  const isKeyboardVisible = useMapStore((state) => state.isKeyboardVisible)
  const setGeoObjectsList = useMapStore((state) => state.setGeoObjectsList)
  const setGeocoderError = useMapStore((state) => state.setGeocoderError)
  const resetSelectedId = useMapStore((state) => state.resetSelectedId)
  const resetSearchedCoordinates = useMapStore((state) => state.resetSearchedCoordinates)
  const resetAdress = useMapStore((state) => state.resetAdress)
  const resetGeoObjectsList = useMapStore((state) => state.resetGeoObjectsList)
  const setSynagoguesList = useMapStore((state) => state.setSynagoguesList)
  const setCurrentPage = useMapStore((state) => state.setCurrentPage)
  const updateGalleryModal = useMapStore((state) => state.updateGalleryModal)
  const updateOverlay = useMapStore((state) => state.setOverlayCountDown)
  const setNearestChabadId = useMapStore((state) => state.setNearestChabadId)
  const setRedirectCountDown = useMapStore((state) => state.setRedirectCountDown)

  const handleBackButtonClick = () => {
    resetSelectedId()
    resetSearchedCoordinates()
    resetAdress()
    resetGeoObjectsList()
    updateGalleryModal({ isOpened: false, id: null })
    setSynagoguesList([])
    updateOverlay({ isOpened: false, timeLeft: 60 })
    setRedirectCountDown(60)
    setGeocoderError(false)
    setNearestChabadId(null)
    hideKeyboard()
    setCurrentPage('home')
  }

  const sendAdress = async () => {
    if (adress && adress.length > 0) {
      try {
        const request = await fetch(`https://geocode-maps.yandex.ru/1.x/?apikey=${process.env.REACT_APP_API_KEY}&geocode=${adress}&lang=ru_RU&format=json`)        
        const response = await request.json()
        const listOfCities = response.response.GeoObjectCollection.featureMember
        const filteredListOfCities: GeoObjectType[] = []
        if (listOfCities.length > 0) {
          setGeocoderError(false)
          listOfCities.forEach((element: any) => {
            if (element && (element.GeoObject.description.includes('Московская область') || element.GeoObject.description.includes('Москва'))) {
              const formatedAdress = formatAddress(element.GeoObject.description.replace('Россия', ''), element.GeoObject.name)
              filteredListOfCities.push({
                adress: formatedAdress,
                coordinates: element.GeoObject.Point.pos.split(' ').map(Number)
              })
            }
          })
        }

        if (filteredListOfCities.length > 0) {
          setGeoObjectsList(filteredListOfCities)
        } else {
          setGeoObjectsList([{adress: null, coordinates: []}])
        }
      } catch (error) {
        setGeocoderError(true)
      }
    }
  }
  
  const buttonTheme = [
    {
      class: "keyboard-button",
      buttons: "1 2 3 4 5 6 7 8 9 0 й ц у к е н г ш щ з х ъ ф ы в а п р о л д ж э {shift} я ч с м и т ь б ю . , ?123 {bksp} {enter} "
    },
    {
      class: "custom-bksp",
      buttons: "{bksp}"
    },
    {
      class: "custom-shift",
      buttons: "{shift}"
    },
    {
      class: "custom-space",
      buttons: "{space}"
    },
    {
      class: "custom-enter",
      buttons: "{enter}"
    }
  ];

  return <>
    <motion.button
      className={styles.back__button_inactive}
      type='button'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      onClick={handleBackButtonClick}
    >
      <img
        className={styles.back__icon}
        src="/icons/arrow_light.svg"
        alt="Стрелка назад"
      />
      {props.backButtonText}
    </motion.button>

    {isKeyboardVisible && <motion.div
      className={styles.wrapper}
      initial={{ opacity: 0, translateY: '517px' }}
      animate={{ opacity: 1, translateY: '0' }}
      transition={{ duration: .3 }}
    >
      <button
        className={styles.back__button_active}
        type='button'
        onClick={handleBackButtonClick}
      >
        <img
          className={styles.back__icon}
          src="/icons/arrow_light.svg"
          alt="Стрелка назад"
        />
        {props.backButtonText}
      </button>

      <button
        className={styles.hide__btn}
        type='button'
        onClick={() => hideKeyboard()}
      >
        Скрыть клавиатуру
      </button>

      <Keyboard
        // onChange={(e) => !adress ? setAdress(e) : setAdress(adress + e[e.length - 1])}
        // onChange={(e) => setAdress(e)}
        layout={
          {
            default: [
              "1 2 3 4 5 6 7 8 9 0",
              "й ц у к е н г ш щ з х ъ",
              "ф ы в а п р о л д ж э",
              "{shift} я ч с м и т ь б ю {bksp}",
              ", {space} . {enter}"
            ],
          }
        }
        buttonTheme={buttonTheme}
        onKeyPress={(key) => {
          if (key === '{enter}') {
            sendAdress()
            hideKeyboard()
          }

          if (key === '1') {
            setAdress(adress + '1')
          }

          if (key === '2') {
            setAdress(adress + '2')
          }

          if (key === '3') {
            setAdress(adress + '3')
          }

          if (key === '4') {
            setAdress(adress + '4')
          }

          if (key === '5') {
            setAdress(adress + '5')
          }

          if (key === '6') {
            setAdress(adress + '6')
          }

          if (key === '7') {
            setAdress(adress + '7')
          }

          if (key === '8') {
            setAdress(adress + '8')
          }

          if (key === '9') {
            setAdress(adress + '9')
          }

          if (key === '0') {
            setAdress(adress + '0')
          }

          if (key === 'й') {
            setAdress(adress + 'й')
          }
          
          if (key === 'ц') {
            setAdress(adress + 'ц')
          }

          if (key === 'у') {
            setAdress(adress + 'у')
          }

          if (key === 'к') {
            setAdress(adress + 'к')
          }

          if (key === 'е') {
            setAdress(adress + 'е')
          }


          if (key === 'н') {
            setAdress(adress + 'н')
          }

          if (key === 'г') {
            setAdress(adress + 'г')
          }
          
          if (key === 'ш') {
            setAdress(adress + 'ш')
          }
          
          if (key === 'щ') {
            setAdress(adress + 'щ')
          }

          if (key === 'з') {
            setAdress(adress + 'з')
          }
                    
          if (key === 'х') {
            setAdress(adress + 'х')
          }

          if (key === 'ъ') {
            setAdress(adress + 'ъ')
          }

          if (key === 'ф') {
            setAdress(adress + 'ф')
          }

          if (key === 'ы') {
            setAdress(adress + 'ы')
          }

          if (key === 'в') {
            setAdress(adress + 'в')
          }

          if (key === 'а') {
            setAdress(adress + 'а')
          }

          if (key === 'п') {
            setAdress(adress + 'п')
          }

          if (key === 'р') {
            setAdress(adress + 'р')
          }

          if (key === 'о') {
            setAdress(adress + 'о')
          }

          if (key === 'л') {
            setAdress(adress + 'л')
          }

          if (key === 'д') {
            setAdress(adress + 'д')
          }

          if (key === 'ж') {
            setAdress(adress + 'ж')
          }

          if (key === 'э') {
            setAdress(adress + 'э')
          }

          if (key === 'я') {
            setAdress(adress + 'я')
          }

          if (key === 'ч') {
            setAdress(adress + 'ч')
          }

          if (key === 'с') {
            setAdress(adress + 'с')
          }

          if (key === 'м') {
            setAdress(adress + 'м')
          }

          if (key === 'и') {
            setAdress(adress + 'и')
          }

          if (key === 'т') {
            setAdress(adress + 'т')
          }

          if (key === 'ь') {
            setAdress(adress + 'ь')
          }

          if (key === 'б') {
            setAdress(adress + 'б')
          }

          if (key === 'ю') {
            setAdress(adress + 'ю')
          }

          if (key === ',') {
            setAdress(adress + ',')
          }

          if (key === '.') {
            setAdress(adress + '.')
          }

          if (key === '{space}') {
            setAdress(adress + ' ')
          }

          if (key === '{bksp}' && adress) {
            setAdress(adress.replace(/.$/, ""))
          }
        }}
      />
    </motion.div>}

    {!isKeyboardVisible && <motion.div
      className={styles.wrapper}
      initial={{ opacity: 1, translateY: '0' }}
      animate={{ opacity: 0, translateY: '517px' }}
      transition={{ duration: .3 }}
    >
      <Keyboard
        onChange={(e) => setAdress(e)}
        layout={
          {
            default: [
              "1 2 3 4 5 6 7 8 9 0",
              "й ц у к е н г ш щ з х ъ",
              "ф ы в а п р о л д ж э",
              "{shift} я ч с м и т ь б ю , . {bksp}",
              "{space}"
            ],
          }
        }
        buttonTheme={buttonTheme}
        onKeyPress={(key) => {
          if (key === '{enter}') {
            sendAdress()
            hideKeyboard()
          }
        }}
      />
    </motion.div>}
  </>
}