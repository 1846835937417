import { useMapStore } from '../../../store'
import { motion } from 'framer-motion'
import styles from './SelectedAdress.module.css'

export const SelectedAdress = (props: {
  adress: string
}) => {
  const resetAdress = useMapStore((state) => state.resetAdress)
  const resetCoordinates = useMapStore((state) => state.resetSearchedCoordinates)
  const resetGeoObjectsList = useMapStore((state) => state.resetGeoObjectsList)
  const resetNearestChabad = useMapStore((state) => state.setNearestChabadId)
  const setIsKeyboardVisible = useMapStore((state) => state.setIsKeyboardVisible)

  return (
    <motion.div
      className={styles.wrapper}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <div className={styles.title__block}>
        <span className={styles.title}>Адрес</span>
        <p className={styles.adress}>{props.adress}</p>
      </div>

      <div className={styles.btns__block}>
        <button className={styles.change__btn} onClick={() => {
          resetAdress()
          resetCoordinates()
          resetGeoObjectsList()
          resetNearestChabad(null)
          setIsKeyboardVisible()
        }}>
          Новый поиск
        </button>

        <button className={styles.change__btn} onClick={() => {
          resetCoordinates()
          resetGeoObjectsList()
          resetNearestChabad(null)
          setIsKeyboardVisible()
        }}>
          Изменить адрес
        </button>
      </div>
    </motion.div>
  )
}