import { Sidebar } from '../../components/ui/SIdebar'
import { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { useMapStore } from '../../store'
import { LngLat } from '@yandex/ymaps3-types'
import {YMap, YMapComponentsProvider, YMapCustomClusterer, YMapDefaultFeaturesLayer, YMapDefaultMarker, YMapDefaultSchemeLayer, YMapMarker } from 'ymap3-components'
import { shuffleArray } from '../../helpers/shuffle-array'
import { Gallerymodal } from '../../components/layout/GalleryModal'
import { InternetConnectionChecker } from '../../components/layout/InternetConnectionChecker'
import { motion } from 'framer-motion'
import styles from './Map.module.css'
import { TouchKeyboard } from '../../components/ui/TouchKeyboard'
import { distanceBetweenLocations } from './get-nearest-ch.js'
import mapStyles from './customization.json'
import { getChabadHouses, getChabadHousesClusters, getMapPageInfo } from '../../utils/directus/requests'
import { ChabadHousesClusersList, ChabadHousesList, MapPage } from '../../types/directus'
import { hasItemById } from '../../helpers/find-synagogue-by-id'
import { PointType } from '../../types/point'

export const Map = () => {
  const idFromStore = useMapStore((state) => state.selectedId)
  const coordinates = useMapStore((state) => state.searchedCoodrinates)
  const synagogoguesList = useMapStore((state) => state.synagoguesList)
  const setSynagoguesList = useMapStore((state) => state.setSynagoguesList)
  const searchedCoodrinates = useMapStore((state) => state.searchedCoodrinates)
  const galleryModal = useMapStore((state) => state.galleryModal)
  const updateGalleryModal = useMapStore((state) => state.updateGalleryModal)
  const selectedId = useMapStore((state) => state.selectedId)
  const setSelectedId = useMapStore((state) => state.setSelectedId)
  const [defaultCenter, setDefaultCenter] = useState<LngLat>([37.582244, 55.672708])
  const setRedirectCountDown = useMapStore((state) => state.setRedirectCountDown)
  const [currentZoom, setCurrentZoom] = useState(10.67)
  const setNearestChabadHouse = useMapStore((state) => state.setNearestChabadId)
  const hideKeyboard = useMapStore((state) => state.setIsKeyboardHidden)
  const [clusters, setClusters] = useState<any[]>()
  const [idsIncludedInClusters, setIdsIncludedInClusters] = useState<number[]>([])
  const [pageInfo, setPageInfo] = useState<MapPage>()

  const mapRef= useRef<any>(null)
  const [isMapLoaded, setIsMapLoaded] = useState(false)
  
  const getSynagogues = useCallback(async () => {
    const synagoguesFromDirectus = await getChabadHouses()
    const clustersFromDirectus = await getChabadHousesClusters()
    if (clustersFromDirectus.length > 0) {
      setClusters(clustersFromDirectus)
    }

    if (synagoguesFromDirectus && synagoguesFromDirectus.length > 1) {
      const shuffledArray = shuffleArray(synagoguesFromDirectus as any)
      setSynagoguesList(shuffledArray)
    }

    if (synagoguesFromDirectus && synagoguesFromDirectus.length === 1) {
      setSynagoguesList(synagoguesFromDirectus as any)
    }
  }, [setSynagoguesList])

  const getMainPageInfo = useCallback(async () => {
    const info = await getMapPageInfo()
    setPageInfo(info)
  }, [])

  useEffect(() => {
    getSynagogues()
    getMainPageInfo()
  }, [getMainPageInfo, getSynagogues])

  useEffect(() => {
    if (clusters && clusters.length > 0) {
      clusters.forEach((cluster) => {
        cluster.included_chabad_houses.forEach((house: ChabadHousesList) => {
          setIdsIncludedInClusters(prev => [...prev, house.id])
        })
      })
    }
  }, [clusters])

  useEffect(() => {
    if (coordinates && coordinates.length > 0) {
      setDefaultCenter([coordinates[0], coordinates[1]] as LngLat)
      setCurrentZoom(17)

      const distanceArray: any[] = []

      synagogoguesList.forEach((item) => {
        const distance = distanceBetweenLocations({longitude: coordinates[0], latitude: coordinates[1]}, {longitude: (item.coordinates as any)[0].coordinate, latitude: (item.coordinates as any)[1].coordinate})
        distanceArray.push({id: item.id, distance: distance})
      })

      if (distanceArray.length > 0) {
        const minimalDistanceObject = (distanceArray: any[]) => {
          return distanceArray.reduce((minObj, currentObj) => {
            return currentObj.distance < minObj.distance ? currentObj : minObj
          }, distanceArray[0])
        }

        const nearestChabadHouse = minimalDistanceObject(distanceArray)
        setNearestChabadHouse(nearestChabadHouse.id)
      }
    } else {
      setCurrentZoom(10.67)
    }

    if (idFromStore) {
      synagogoguesList.forEach((synagogue) => {
        if (synagogue.id === idFromStore) {
          setDefaultCenter([(synagogue.coordinates as any)[0].coordinate, (synagogue.coordinates as any)[1].coordinate] as LngLat)
          setCurrentZoom(17)
        }
      })
    }
  }, [coordinates, idFromStore, setNearestChabadHouse, synagogoguesList])

  useEffect(() => {
    if (selectedId && (selectedId === 22 || selectedId === 12 || selectedId === 18)) {
      setCurrentZoom(20)
    } else {
      setCurrentZoom(17)
    }
  }, [selectedId])

  useEffect(() => {
    if (mapRef) {
      setTimeout(() => {
        setIsMapLoaded(true)
      }, 3100)
    }
  }, [])

  const marker = useCallback(
    (feature: { type: string, id: string, title: string, geometry: { type: string, coordinates: LngLat }}) => (
    <YMapMarker coordinates={feature.geometry.coordinates as LngLat}>
      <div className={styles.marker}>
        <img
          alt='Иконка синагоги'
          src={selectedId === parseInt(feature.id) ? '/icons/synagogue_active.svg' : '/icons/synagogue_default.svg'}
          style={{width: '70px', height: '70px'}}
          onTouchStart={() => {
            hideKeyboard()
            setSelectedId(parseInt(feature.id))
            // setDefaultCenter(feature.geometry.coordinates as LngLat)
            // setCurrentZoom(mapRef.current.zoom)
          }}
          onTouchMove={() => {
            hideKeyboard()
            setSelectedId(parseInt(feature.id))
            // setDefaultCenter(feature.geometry.coordinates as LngLat)
            // setCurrentZoom(mapRef.current.zoom)
          }}
          onClick={() => {
            hideKeyboard()
            setSelectedId(parseInt(feature.id))
            // setDefaultCenter(feature.geometry.coordinates as LngLat)
            // setCurrentZoom(mapRef.current.zoom)
          }}
        />

        <span className={selectedId === parseInt(feature.id) ? styles.synagogue__name_active : styles.synagogue__name_default}>
          {feature.title}
        </span>
      </div>
    </YMapMarker>
  ), [hideKeyboard, selectedId, setSelectedId])

  return (
    <motion.main
      className={styles.page__wrapper}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      onClick={() => setRedirectCountDown(90)}
      onTouchMove={() => setRedirectCountDown(90)}
    >
      <div className={styles.copyright__overlay} />
      <InternetConnectionChecker />
      <Sidebar title={pageInfo && pageInfo.chabad_houses_list_title} />
      <div className={isMapLoaded ? styles.wrapper_active : styles.wrapper_inactive}>
      <YMapComponentsProvider apiKey={`${process.env.REACT_APP_API_KEY}`} lang='ru_RU'>
        <YMap
          location={{ center: defaultCenter, zoom: currentZoom, easing: 'linear', duration: 300 }}
          copyrightsPosition='bottom right'
          zoomRange={{min: 10.67, max: 20}}
          ref={mapRef}
        >
          <YMapDefaultSchemeLayer customization={mapStyles as any} />
          <YMapDefaultFeaturesLayer />
          {clusters && clusters.length > 0 && <Fragment>
            {clusters.map((cluster: ChabadHousesClusersList) => {
              const points: PointType[] = []

              cluster.included_chabad_houses.forEach((house: ChabadHousesList) => {
                const houseObject = {
                  type: "Feature",
                  id: house.id.toString(),
                  title: house.chabad_house_name,
                  geometry: {
                    type: "Point",
                    coordinates: [(house.coordinates as any)[0].coordinate, (house.coordinates as any)[1].coordinate],
                  }
                }

                points.push(houseObject)
              })

              return <YMapCustomClusterer
                marker={marker as any}
                key={cluster.id}
                cluster={(coordinates: LngLat) => (
                  <YMapMarker
                    coordinates={coordinates}
                    onClick={() => {
                      hideKeyboard()
                      setDefaultCenter([(cluster.coordinates as any)[0].coordinate, (cluster.coordinates as any)[1].coordinate] as LngLat)
                      setCurrentZoom(cluster.zoom ? cluster.zoom : 10.67)
                  }}>
                    <motion.div
                      className={selectedId && hasItemById(cluster.included_chabad_houses, selectedId) ? styles.cluster__marker_active : styles.cluster__marker}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 1 }}
                      onTouchStart={() => {
                        hideKeyboard()
                        setDefaultCenter([(cluster.coordinates as any)[0].coordinate, (cluster.coordinates as any)[1].coordinate] as LngLat)
                        setCurrentZoom(cluster.zoom ? cluster.zoom : 10.67)
                      }}
                      onTouchMove={() => {
                        hideKeyboard()
                        setDefaultCenter([(cluster.coordinates as any)[0].coordinate, (cluster.coordinates as any)[1].coordinate] as LngLat)
                        setCurrentZoom(cluster.zoom ? cluster.zoom : 10.67)
                      }}
                    >
                      <img
                        alt='Иконка синагоги'
                        src={selectedId && hasItemById(cluster.included_chabad_houses, selectedId) ? '/icons/synagogue_cluster_active.svg' : '/icons/synagogue_cluster.svg'}
                        style={{zIndex: '9999'}}
                        // onClick={() => {
                        //   setSelectedId(synagogue.id)
                        //   // setDefaultCenter(synagogue.coordinates as LngLat)
                        // }}
                      />
                    </motion.div>
                  </YMapMarker>
                )}
                gridSize={cluster.grid_size as any}
                features={points as any}
              />
            })}
          </Fragment>}

          {synagogoguesList && clusters && clusters.length > 0 && synagogoguesList.map((synagogue) => {
            if (!idsIncludedInClusters.includes(synagogue.id)) {
              return <YMapMarker coordinates={[(synagogue.coordinates as any)[0].coordinate, (synagogue.coordinates as any)[1].coordinate] as LngLat} key={synagogue.id}>
                <motion.div
                  className={styles.marker}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1 }}
                >
                  <img
                    alt='Иконка синагоги'
                    src={selectedId === synagogue.id ? '/icons/synagogue_active.svg' : '/icons/synagogue_default.svg'}
                    style={{width: '70px', height: '70px'}}
                    onTouchStart={() => {
                      hideKeyboard()
                      setSelectedId(synagogue.id)
                      setDefaultCenter([(synagogue.coordinates as any)[0].coordinate, (synagogue.coordinates as any)[1].coordinate] as LngLat)
                      setCurrentZoom(20)
                    }}
                    onTouchMove={() => {
                      hideKeyboard()
                      setSelectedId(synagogue.id)
                      setDefaultCenter([(synagogue.coordinates as any)[0].coordinate, (synagogue.coordinates as any)[1].coordinate] as LngLat)
                      setCurrentZoom(20)
                    }}
                    onClick={() => {
                      hideKeyboard()
                      setSelectedId(synagogue.id)
                      setDefaultCenter([(synagogue.coordinates as any)[0].coordinate, (synagogue.coordinates as any)[1].coordinate] as LngLat)
                      setCurrentZoom(20)
                    }}
                  />
                  <span className={selectedId === synagogue.id ? styles.synagogue__name_active : styles.synagogue__name_default}>
                    {synagogue.chabad_house_name}
                  </span>
                </motion.div>
              </YMapMarker>
            } else {
              return null
            }
          })}

          {synagogoguesList && clusters && clusters.length === 0 && synagogoguesList.map((synagogue) => {
              return <YMapMarker coordinates={[(synagogue.coordinates as any)[0].coordinate, (synagogue.coordinates as any)[1].coordinate] as LngLat} key={synagogue.id}>
                <motion.div
                  className={styles.marker}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1 }}
                >
                  <img
                    alt='Иконка синагоги'
                    src={selectedId === synagogue.id ? '/icons/synagogue_active.svg' : '/icons/synagogue_default.svg'}
                    style={{width: '70px', height: '70px'}}
                    onTouchStart={() => {
                      hideKeyboard()
                      setSelectedId(synagogue.id)
                      setDefaultCenter([(synagogue.coordinates as any)[0].coordinate, (synagogue.coordinates as any)[1].coordinate] as LngLat)
                      setCurrentZoom(20)
                    }}
                    onTouchMove={() => {
                      hideKeyboard()
                      setSelectedId(synagogue.id)
                      setDefaultCenter([(synagogue.coordinates as any)[0].coordinate, (synagogue.coordinates as any)[1].coordinate] as LngLat)
                      setCurrentZoom(20)
                    }}
                    onClick={() => {
                      hideKeyboard()
                      setSelectedId(synagogue.id)
                      setDefaultCenter([(synagogue.coordinates as any)[0].coordinate, (synagogue.coordinates as any)[1].coordinate] as LngLat)
                      setCurrentZoom(20)
                    }}
                  />
                  <span className={selectedId === synagogue.id ? styles.synagogue__name_active : styles.synagogue__name_default}>
                    {synagogue.chabad_house_name}
                  </span>
                </motion.div>
              </YMapMarker>
          })}

          {searchedCoodrinates && searchedCoodrinates.length > 0 && <YMapDefaultMarker
            coordinates={searchedCoodrinates as LngLat}
            color={'#25B3BC'}
          />}
        </YMap>
      </YMapComponentsProvider>
    </div>

    {galleryModal.isOpened && galleryModal.id && selectedId && synagogoguesList.length > 0 &&
      <Gallerymodal
        selectedSynagogueId={galleryModal.id}
        onClose={() => updateGalleryModal({isOpened: false, id: null})}
    />}

    <TouchKeyboard backButtonText={pageInfo && pageInfo.back_button} />
  </motion.main>
  )
}