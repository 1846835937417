interface Item {
  id: number;
}

export const hasItemById = (items: any[], idToSearch: number): boolean => {
  return items.find(item => item.id === idToSearch) !== undefined;
}

// export const hasItemById = (arr: any[], id: number) => {
//   return arr.map(obj => {
//     const property = obj.included_chabad_houses;
//     if (Array.isArray(property)) {
//       return property.find(item => item.id === id) !== undefined;
//     }
//     return false;
//   });
// }
