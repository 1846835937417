import { motion, AnimatePresence } from 'framer-motion'
import styles from './ScreensaverOverlay.module.css'

export const ScreensaverOverlay = (props: {
  isVisible: boolean
}) => {
  return (
    <AnimatePresence>
      {props.isVisible && <motion.div
        className={styles.screensaver}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      />}
    </AnimatePresence>

  )
}