import { TouchEvent } from 'react'
import { motion } from 'framer-motion'
import { HTMLBlock } from '../HTMLBlock'
import styles from './Statictext.module.css'

export const StaticText = (props: {
	text: object | null | undefined
	onClick?: () => void
	onTouchMove?: (e: TouchEvent<HTMLDivElement>) => void
}) => {
  return (
    <motion.div
			className={styles.wrapper_scrollable}
			onClick={props.onClick}
			onTouchMove={props.onTouchMove}
			initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
		>
			<div className={styles.title__block}>
        {Array.isArray(props.text) &&
        <HTMLBlock rawHtml={props.text[0].text} />}
				<div className={styles.gradient__overlay} />
      </div>

			<div
				className={styles.wrapper_static}
      >
        {Array.isArray(props.text) && props.text && props.text.map((item, index) => {
          if (Array.isArray(props.text) && props.text.indexOf(item) !== 0) {
            return <HTMLBlock key={index} className='paragraph' rawHtml={item.text} />
          }

          return null
        })}
      </div>
		</motion.div>
  )
}