const Sinagoge1 = {latitude: 55.75806, longitude: 37.622727};
const Sinagoge2 = {latitude: 55.764342, longitude: 37.605671};
const Sinagoge3 = {latitude: 55.75806, longitude: 37.622727};
let myLocation = {latitude: 55.762592, longitude: 37.634129};

const degreesToRadians = degrees => degrees * (Math.PI / 180);
const radiansToDegrees = radians => radians * (180 / Math.PI);


const centralSubtendedAngle = (locationX, locationY) => {
  const locationXLatRadians = degreesToRadians(locationX.latitude);
  const locationYLatRadians = degreesToRadians(locationY.latitude);
  return radiansToDegrees(
      Math.acos(
        Math.sin(locationXLatRadians) * Math.sin(locationYLatRadians) +
          Math.cos(locationXLatRadians) *
            Math.cos(locationYLatRadians) *
            Math.cos(
              degreesToRadians(
                Math.abs(locationX.longitude - locationY.longitude)
              )
        )
      )
    );
}

const earthRadius = 6371
const greatCircleDistance = angle => 2 * Math.PI * earthRadius * (angle / 360);
export const distanceBetweenLocations = (locationX, locationY) =>
greatCircleDistance(centralSubtendedAngle(locationX, locationY));